import React from 'react'
import MediaQuery from 'components/MediaQuery'
import { observer } from 'mobx-react-lite'
import StoryList from 'components/StoryList'
import FeaturedStories from 'components/FeaturedStories'
import BrandDetailContainer from './BrandDetailContainer'
import { THEME } from 'utils'

const BrandDetailStories = ({ items, featured, next, hasMore, title = 'Activity', adSettings }) => {
  const displayOptions = {
    primary: { large: true, featuredImage: true, showAuthor: true, flex: true, showAuthorImage: true },
    secondary: { large: false, featuredImage: true }
  }
  const displayOptionsMobile = {
    primary: { featuredImage: true, large: true },
    secondary: { featuredImage: true }
  }
  return (
    <BrandDetailContainer>
      {featured?.length > 0 && (
        <div className='featuredSection'>
          <MediaQuery maxWidth={THEME.BREAKPOINTS.MD}>
            <FeaturedStories featured={featured} displayOptions={displayOptionsMobile} />
          </MediaQuery>
          <MediaQuery minWidth={THEME.BREAKPOINTS.MD - 1}>
            <FeaturedStories featured={featured} displayOptions={displayOptions} />
          </MediaQuery>
        </div>
      )}
      <div className='storyList'>
        <h1 className='feedPanelTitle'>{title}</h1>
        <StoryList items={items} next={next} hasMore={hasMore} adSettings={adSettings} disableNativeAds />
      </div>
      <style jsx>{`
        .featuredSection {
          padding: 1.125rem 1.125rem 0;
          background-color: #fff;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
          margin-bottom: 20px;
        }
        .storyList {
          background-color: #fff;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
        }
        .feedPanelTitle {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 1.875rem;
          font-weight: 600;
          text-transform: uppercase;
          color: ${THEME.COLORS.SECONDARY};
          line-height: 1.2;
          padding: 0.75rem 1.125rem;
          margin: 0;
        }
      `}</style>
    </BrandDetailContainer>
  )
}

export default observer(BrandDetailStories)
